import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['photoItem'];

  connect() {
    this.index = 0;
    this.interval = setInterval(() => {
      this.showNextItem();
    }, 500); // Délai d'une seconde entre chaque élément
  }

  disconnect() {
    clearInterval(this.interval);
  }

  showNextItem() {
    if (this.index < this.photoItemTargets.length) {
      const item = this.photoItemTargets[this.index];
      item.classList.add('show');
      this.index++;
    } else {
      clearInterval(this.interval);
    }
  }
}
