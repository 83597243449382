import { Controller } from "@hotwired/stimulus"

// video_controller.js

export default class extends Controller {
  static targets = ['playButton'];

  connect() {
    this.video = this.element;
    this.playButton = this.playButtonTarget;
  }

  togglePlay() {
    if (this.video.paused) {
      this.video.play();
      this.playButton.textContent = 'Pause';
    } else {
      this.video.pause();
      this.playButton.textContent = 'Play';
    }
  }
}
