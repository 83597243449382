import { Controller } from "@hotwired/stimulus"
 import 'bootstrap/js/dist/tooltip'; // Importez le module Tooltip de Bootstrap
// Connects to data-controller="tooltip"
export default class extends Controller {
  connect() {
    this.element.querySelectorAll('[data-toggle="tooltip"]').forEach((tooltipElement) => {
      new bootstrap.Tooltip(tooltipElement); // Initialisez le tooltip avec Bootstrap
    });
  }
}
