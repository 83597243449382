import { uploadFile } from "@rails/activestorage";
import { Trix } from "trix";

Trix.config.attachments.preview.caption = {
  name: false,
  size: false
};

document.addEventListener("trix-attachment-add", function(event) {
  if (event.attachment.file) {
    uploadFile(event.attachment.file, { direct_upload: true }, function(attachment) {
      event.attachment.setAttributes({
        url: attachment.url,
        href: attachment.href,
        direct_upload: true
      });
    });
  }
});
