//= require trix
require("@rails/actiontext");
require("trix");

import "trix"
import "@hotwired/turbo-rails"
import "bootstrap"
import "@rails/actiontext"
import "./controllers"
import "trix"
import "@rails/actiontext"
