import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const slideElement = this.element.querySelector(".sliding");
    const pubElement = this.element.querySelector(".pub");
    const pubWidth = pubElement.offsetWidth;

    const tps = Math.trunc(pubWidth / 100);
    slideElement.style.animation = `animate ${tps * 2}s linear infinite`;

    const isEven = this.element.classList.contains("even");

    if (!isEven) {
      slideElement.style.animation = `animate-inverse ${tps * 2}s linear infinite`;
    }

  }
}
